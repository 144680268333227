
/* :root{
    --body: #FFF;
    --red: #ef2853;
    --redx: #ff7348;
    --green: #87E64C;
    --strok1: #40A402;
    --success: #1999FF;
    --icon1: #352e28;
    --default: #707070;
    --dark: #232323;
}
body.white-mode{
    --body: #232323;
    --icon1: #cea176;
} */

.mamas-loader-body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: var(--body);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
  }
.mamas-primary-loader{
    position: relative;
    cursor: default;
}
.mamas-primary-loader span{
    position: relative;
    display: inline-flex;
    font-size: 3em;
    color: transparent;
    -webkit-text-stroke: 1px var(--strok1);
    text-transform: uppercase;
    font-weight: 900;
    animation: animate1 3s ease-in-out infinite;
    animation-delay: calc(0.25s * var(--i));
    margin-right: 5px;
    font-family: 'poppins', sans-serif;
}
.mamas-primary-loader span:last-child{
    margin-right: 0px;
}
.mamas-loader-dot-icon{
    font-size: 1em !important;
}
@keyframes animate1{
    0%{
        transform: translateY(0px);
        color: transparent;
        text-shadow: none;
    }
    20%{
        transform: translateY(-60px) rotate(-30deg);
        color: var(--green);
        text-shadow: 0 0 5px var(--green), 0 0 7px var(--green), 0 0 10px var(--green);
    }
    30%{
        transform: translateY(-60px) rotate(30deg);
    }
    40%,100%{
        transform: translateY(0px);
        color: transparent;
        text-shadow: none;
    }
}

/* ---- MAMA'S LOADER TWO --- */
.mamas-primary-loader2{
    text-transform: uppercase;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px var(--strok1);
    background: linear-gradient(transparent, transparent, transparent, transparent, var(--icon1), transparent, transparent, transparent, transparent);
    -webkit-background-clip: text;
    background-size: 400%;
    background-position: 0;
}
.mamas-primary-loader2 span{
    font-family: var(--nato-sans);
    font-size: 2em;
    animation: animate2 3s ease-in-out infinite;
    animation-delay: calc(0.25s * var(--i));
    font-weight: 800;
}
@keyframes animate2{
    0%{
        -webkit-text-stroke: 1px var(--green);
        background: var(--green);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 0px 0px;

    }
    30%, 100%{
        -webkit-text-stroke: 1px var(--green);
        background: var(--strok1);
        -webkit-background-clip: text;
        -webkit-text-fill-color: var(--green);
        margin: 0px 5px;

    }
    70%,  100%{
        -webkit-text-stroke: 1px var(--green);
        background: var(--green);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 0px 0px;
    }
}
