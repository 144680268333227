

.pointer{
  cursor: pointer;
}

.logoM {
    background: var(--redx);
    width: 30px;
    height: 30px;
    border-radius: 15px 0px 16px 0px;
    font-size: 20px;
    text-align: center;
    line-height: 30px;
    font-weight: 800;
    
  }

  .mamass-loader-body{
    display: flex;
    align-items: center;
    height: 50px;
}
.mamass-primary-loader{
    position: relative;
    cursor: default;
}
.mamass-primary-loader span{
    position: relative;
    display: inline-flex;
    font-size: 20px;
    color: transparent;
    -webkit-text-stroke: 1px var(--logo);
    text-transform: uppercase;
    font-weight: 900;
    animation: animatex 3s ease-in-out infinite;
    animation-delay: calc(0.25s * var(--i));
    margin-right: 5px;
    font-family: 'poppins', sans-serif;
}
.mamas-logo .mamas-primary-loader span:last-child{
    margin-right: 0px;
}
.mamas-logo .mamas-loader-dot-icon{
    font-size: 20px !important;
}
@keyframes animatex{
    0%{
        transform: translateY(0px);
        color: transparent;
        text-shadow: none;
    }
    20%{
        transform: translateY(-10px) rotate(-30deg);
        color: var(--green);
        text-shadow: 0 0 5px var(--redx), 0 0 7px var(--redx), 0 0 10px var(--redx);
    }
    30%{
        transform: translateY(-20px) rotate(30deg);
    }
    40%,100%{
        transform: translateY(0px);
        color: transparent;
        text-shadow: none;
    }
}

@media screen and (max-width: 768px){
    .invisible-sm{
        display: none;
    }
.mamass-primary-loader span{
    margin-right: 2px;
}
}

.mamas-navbar{
    background: var(--nav-bg);
    height: 50px;
    width: 100%;
    color: #fff;
  }

.earn-money-mamas {
    border: 1px solid var(--nav-border);
    padding: 2px 10px;
    border-radius: 4px;
    /* background: var(--nav-bg); */
    color: var(--color) !important;
    font-size: 14px;
    letter-spacing: 0.5px;
  }

  .earn-money-mamas span {
    background: rgba(0,0,0,0.25);
    text-transform: uppercase;
    font-weight: 600;
    padding: 2px 5px;
    border-radius: 4px;
    box-shadow: 5px 5px 15px rgba(0,0,0,0.1);
    font-family: var(--Italian-font);
    color: var(--font-muted1);
  }

  .mamas-top-nav-icons{
    height: 100%;
    display: flex;
    justify-content: right;
    align-items: center;
    gap: 25px;
  }

  .mamas-user-img{

  }
  .mamas-user-img img{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid var(--nav-border);
    cursor: pointer;
  }
  .mamas-user-img img:hover{
    transform: scale(1.2);
  }
  .mamas-social-icons, .mamas-order-icons, .mamas-theme-mood{
    position: relative;
  }
  .mamas-social-icons::after, .mamas-order-icons::after, .mamas-theme-mood::after {
    position: absolute;
    content: '';
    top: 50%;
    right: -12px;
    transform: translateY(-50%);
    width: 1px;
    height: 70%;
    background: var(--nav-border);
  }
  .mamas-social-icons i, .mamas-order-icons i, .mamas-theme-mood i, .mamas-login-icon i {
    font-size: 18px;
    color: var(--nav-text-color);
    cursor: pointer;
  }
  .mamas-social-icons i:hover, .mamas-order-icons i:hover, .mamas-theme-mood i:hover {
    transform: scale(1.1);
  }

  .mamas-social-icons i:hover, .mamas-order-icons i:hover, .mamas-theme-mood i:hover, .mamas-login-icon i:hover {
    color: var(--hover1);
  }
  .mamas-theme-mood i{
    color: wheat
  }

  /* ---- Side menu ----  */


  .mamas-user-menu-handle{
    position: absolute;
    top: 60px;
    right: 0;
    width: 280px;
    height: auto;
    padding: 20px 15px;
    padding-bottom: 0;
    background: var(--nav-bg);
    box-shadow: var(--shadow2);
    border-radius: 6px;
  }
  .open-mamas-user-meanu{
    visibility: visible;
    opacity: 1;
  }
  .close-mamas-user-meanu{
    visibility: hidden;
    opacity: 0;
  }

  .mamas-user-profile-box {
    box-shadow: var(--shadow2);
    padding: 5px 10px;
    border-radius: 6px;
  }
  .mamas-user-profile-box img{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
    border: 2px solid var(--nav-border);
  }
  .mamas-user-profile-box h2{
    font-size: 15px;
    font-weight: 500;
    color: var(--logo);
  }

  .mamas-settings-menu{
    margin-top: 10px;
  }
.mamas-settings-menu a{
    width: 100%;
    height: 35px;
    box-shadow: var(--shadow1);
    background: var(--menu1);
    margin-top: 5px;
    border-radius: 4px;
    line-height: 30px;
    padding: 0px 10px;
  }
  .mamas-settings-menu a:hover{
    opacity: 0.7;
  }
  .mamas-settings-menu a span, .mamas-settings-menu a i{
    color: var(--logo);
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 30px;
  }
.mamas-user-menu-handle p{
    color: var(--logo);
    font-size: 12px;
    opacity: 0.8;
    line-height: 14px;
    margin-top: 5px;
}