/*  Nato sans */
@import url('https://fonts.googleapis.com/css2?family=Italiana&family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
/*  Italian */
@import url('https://fonts.googleapis.com/css2?family=Italiana&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

:root{
    --body: #FFF;
    --blue: #4409b8;
    --color: #FFF;
    --color2: #9691A6;
    --red: #ef2853;
    --redx: #ff7348;
    --green: #87E64C;
    --success: #1999FF;
    --icon1: #352e28;
    --default: #707070;
    --default2: #1e1131b2;
    --dark: #232323;
    --logo: #D2BDFB;
    --hover1: #FFF;
    --border: #d5d5d5;
    --subscriptionBR: #e7e7e7;
    --menu1: #2000607d;
    --tempBody: #f2f4f7;
    --title1: #2c1371;
    --buttonBG1: #350c71;
    --widgetBorder1: #eaecf0;
    --widgetBackground: #f4f4f9;
    --whatsApp: #12b76a;
    --whatsBBG: #12b76a12;
    --facebook: #4478ff;
    --facebbbG: #4478ff2b;
    --spanBG: #421b7b;
    --title2: #2b0f55d9;
    --pText: #67748E;
    --opacityBG: #421b7b05;

    --foterBG1 :rgba(39, 37, 42, 0.05);


    --font-muted1: rgba(255,255,255,0.65);
    --nav-border: rgba(255,255,255,0.4);
    --nav-text-color: rgba(255,255,255,0.85);


    --nav-bg: linear-gradient(to right, #400baa, #4608c0);
    --default-bg: linear-gradient(to right, #220854, #380162);

    --shadow1: 10px 10px 40px rgba(66, 10, 177, 0.16);
    --shadow2: 0 2px 8px 0 rgba(1, 1, 1, 0.31);
    --shadow3: 0 2px 20px 0 rgba(1,1,1,.1);
    --shadow4: 5px 5px 35px rgba(0, 0, 0, 0.068);


    /* --Fonts-- */
    --Italian-font:"Italiana", sans-serif;
    --nato-sans: "Noto Sans", sans-serif;
}
body.white-mode{
    --body: #232323;
    --tempBody: #2b2b2b;
    --icon1: #cea176;
    --color: #d2bdfb;
    --color2: #d2bdfbc2;
    --title1: #d2bdfb;
    --blue: #d2bdfbc2;;
    --border: #484848;
    --whatsApp: #12b76a;
    --facebook: #4478ff;
    --dark: #d5d5d5;
    --menu1: #1f1a26;
    --default: #D2BDFB;
    --default2: #d1b8f7ab;
    --buttonBG1: #313137;
    --spanBG: #7f54d296;
    --title2: #d1b8f7ab;
    --opacityBG: #421b7b05;
    --widgetBorder1: rgba(114, 86, 176, 0.4);
    --widgetBackground: linear-gradient(to right, #1e1c19, #2b2931);




    --font-muted1: rgba(255,255,255,0.65);
    --nav-border: rgba(114, 86, 176, 0.4);
    --subscriptionBR: rgba(114, 86, 176, 0.4);
    --nav-text-color: rgba(238, 238, 238, 0.85);
    --foterBG1 :rgba(39, 37, 42, 0.05);


    --nav-bg: linear-gradient(to right, #1e1c19, #2b2931);
    --default-bg: linear-gradient(to right, #1e1c19, #2b2931);

}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: var(--nato-sans);
}
body{
    font-weight: 500;
    background-color: var(--body);
    transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
}
.parentTR{
    transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
}
p, span{
    font-size: 15px;
    transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
}
h1, h2, h3, h4, h5, h6{
    font-size: 16px;
    transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
}
div{
    transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
}
ul{
    margin-left: 0;
    padding-left: 0;
    list-style: none;
}
.features-list{
    background: #421b7b0d;
    padding: 2px 5px;
    border-radius: 4px;
    font-size: 12px;
    border: 1px solid var(--widgetBorder1);
    color: var(--pText);
  }
a{
    text-decoration: none;
}
.center-center{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.center-midle{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.tr2{
    transition: all .3s ease-in-out;
}
.tr3{
    transition: all .3s ease-in-out;
}
.title1{
    font-size: 20px;
    font-weight: 500;
    color: var(--title2);
}
.title1{
    font-size: 20px;
    font-weight: 700;
    color: var(--title2);
    text-transform: uppercase;
}
.text{
    color: var(--pText);
    word-break:keep-all;
    font-weight: 400;
}
.label{
    width: 100%;
    display: block;
    font-size: 16px;
    color: var(--title1);
    font-weight: 500;
}
.hidden{
    visibility: hidden;
    opacity: 0;
}
.visiblity{
    visibility: visible !important;
    opacity: 1 !important;
}
.mbtn{
  border-style: none;
  background: transparent;
}
/* --- Home Page --- */

.mamas-login-caption{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 30px;
}
.mamas-login-template-theme{
    background: url('../images/mamas\ bio.png') no-repeat center center;
    background-size: cover;
}


.mamas-login-fields div{
    width: 100%;
    display: block;
}
.mamas-login-fields div label{
    width: 100%;
    display: block;
    font-size: 16px;
    color: var(--title1);
    font-weight: 500;

}
.mamas-login-fields input{
    background: transparent;
    border-color: var(--color2);
}
.mamas-login-fields input:focus {
    background: transparent;
}
.mamas-login-content-ass{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
}
.mamas-login-content-ass a{
    color: var(--title1);
}
.mamas-login-content-ass a:hover{
    opacity: 0.8;
}
.login-regist, .or{
    display: block;
    width: 100%;
}
.login-regist{
    background: var(--nav-bg);
}
.or{
    text-align: center;

}
.login-to-sign-btn{
    width: 100%;
    text-align: center;
    padding: 10px 0px;
}
.login-to-sign-btn a{
    color: var(--blue);

}


/* ---- mamas-widget-area ---- */
.mamas-widget-area{
    border: 1px solid var(--widgetBorder1);
    background: var(--widgetBackground);
    padding: 10px 15px;
    border-radius: 5px;
}
.mamas-widget-area h2, .mamas-widget-area h4{
    font-size: 15px;
    color: var(--default2);
    letter-spacing: 0.5px;
}

.suppor-social{
    width: 100%;
}
.suppor-social a{
    background: var(--whatsBBG);
    color: var(--whatsApp);
    padding: 5px 10px;
    border-radius: 2px;
}
.suppor-social a i{
    margin-left: 10px;
    font-size: 18px;
    line-height: 20px;
}
.suppor-social a:last-child{
    background: var(--facebbbG);
    color: var(--facebook);
}
.suppor-social p, .mamas-widget-area ul li{
    color: var(--color2);
    word-break:keep-all;
    font-weight: 400;
}

/* --- Subscription --- */
.subscriptions-grids{
    width: 100%;
}
.subscription{
    width: 100%;
    border: 1px solid var(--subscriptionBR);
    background: var(--widgetBackground);
    box-shadow: 5px 5px 35px rgba(0, 0, 0, 0.068);
    padding: 10px 15px;
    margin-bottom: 20px;
    position: relative;
    padding-bottom: 15px;
}


.subscription:hover{
    box-shadow: 5px 5px 35px rgba(0, 0, 0, 0.1);
    top: -1px;
    border-color:  #52288491;
}
.subscription-header{
    position: relative;
    height: 60px;
}
.subscription-header .price{
    position: absolute;
    top: 5px;
    right: 10px;
    width: 50px;
    height: 50px;
    background: var(--spanBG);
    font-size: 18px;
    text-align: center;
    line-height: 50px;
    border-radius: 50%;
    color: var(--color);
}
.subscription-header .price span {
    position: absolute;
    top: -5px;
    right: 0;
    background: var(--red);
    width: 20px;
    height: 20px;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    border-radius: 50%;
  }
.subscription-header h1{
    font-size: 14px;
    color: var(--color);
    padding: 5px 10px;
    border-radius: 25px;
    background: var(--spanBG);
    font-weight: 400;
}
.subscription ul li{
    background: #421b7b0d;
    padding: 2px 5px;
    border-radius: 4px;
    font-size: 12px;
    border: 1px solid var(--widgetBorder1);
    color: var(--pText);

}
.subscription h4{
    color: #9082a9;
}
.subscription a {
    width: 100%;
    background: var(--opacityBG);
    text-align: center;
    border-radius: 25px;
    padding: 5px 10px;
    color: var(--title2);
    letter-spacing: 0.6px;
    border: 1px solid var(--subscriptionBR);
  }

  .subscription a:hover {
    transform: scale(0.9);
  }

  /* --- Single package details --- */
  .baner-one{
    width: 100%;
    background: url('../images/mamas-baner.jpg') no-repeat center center;
    background-size: cover;
    height: 250px;
    position: relative;
    z-index: 1;
  }
  .baner-one::after{
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #15062b;
    opacity: 0.8;
    z-index: -1;
  }
  .baner-one h1{
    font-size: 20px;
    letter-spacing: 0.5px;
  }
  .baner-one h1,   .baner-one span {
    color: var(--color);
  }
  .I_have_coupon{
    font-size: 12px;
    text-decoration: underline;
    color: var(--whatsApp);
  }
  .I_have_coupon:hover{
    color: var(--whatsApp);
    opacity: 0.5;
  }


  /* ---- CUPUON ---- */

  .cupuon-form{
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #31245324;
    overflow: hidden;
    z-index: 1;
  }
  .cupuon-form form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 50%;
    background: var(--body);
    border-radius: 5px;
    box-shadow: var(--shadow4);
    padding: 25px 30px;
  }

  .cupuon-form .close {
    position: absolute;
    content: '';
    top: -22px;
    left: 50%;
    transform: translateX(-50%);
    background: var(--red);
    width: 45px;
    height: 45px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 50%;
    line-height: 45px;
    text-align: center;
    cursor: pointer;
  }
  .cupuon-form .close i{
    font-size: 20px;
    color: var(--body);
  }
  .close:hover i{
    font-size: 25px;
  }

  /* ---- Purchase --- */
  .sender-number span, sender-number i{
    padding: 2px 8px;
    background: var(--widgetBorder1);
    letter-spacing: 0.8px;
    border-radius: 25px;
    font-size: 15px;
    font-weight: 400;
  }
  .sender-number i{
    background: var(--whatsBBG);
  }
  .sender-number i:hover{
    color: var(--whatsApp);
  }

  .mamas-bio-details{
    width: 100%;
    height: 95vh;
    border: 1px solid;
    margin: 10px 0;
  }

  .mamas-bio-details img{
    width: 80px;
    height: 80px;
    object-fit: cover;
    border: 2px;
    border-style: solid;
    border-radius: 50%;
  }
  .mamas-bio-details .name{
    text-align: center;
    font-size: 16px;
    text-transform: capitalize;
  }
  .mamas-bio-details p{
    text-align: center;
    margin-bottom: 0;
    padding: 0px 30px;

  }
  .bios-socials{
    padding: 20px 0px;
  }
  .bios-socials a{
    margin-left: 15px;
    color: #344767;
    transition: all .3s ease-out;
  }
  .bios-socials a i{
    font-size: 18px;
  }
  .mamas-bios-buttons{
    width: 100%;
    padding: 0px 30px;
  }
  .mamas-bio-button{
    width: 100%;
    height: 60px;
    margin-bottom: 10px;
    box-shadow: var(--shadow4);
    border: 1px solid;
    position: relative;

  }
  .mamas-bio-button:hover{
    box-shadow: var(--shadow2);
  }
  .mamas-bio-button span{
    margin-left: 10px;
  }
  .mamas-bio-button img{
    width: 10%;
    height: 100%;
    border-radius: 0;
    border-style: none;
    object-fit: cover;

  }
  .mamas-bio-button i {
    position: absolute;
    top: 29%;
    right: 1%;
    font-size: 18px;
    transform: rotate(90deg);
    animation: iconAnim 2s linear infinite;
  }
  @keyframes iconAnim{
    0%{
        right: 1%;

    }50%{
        right: 2%;

    }100%{
        right: 1%;

    }

  }

  /* --- Sign up --- */
  .show-pass {
    position: absolute;
    content: '';
    top: 8px;
    right: 5px;
    width: 20px !important;
    height: 20px;
    cursor: pointer;
  }

  /* --- Dasboard widgets --- */
  .analytics-list{
    border: 1px solid var(--widgetBorder1);
    background: #421b7b0d;
    padding: 10px 20px;
    border-radius: 4px;
  }
  .analytics-list-div{
    margin-top: 5px;
  }
  .analytics-list-anker{
    color: var(--whatsApp);
    font-size: 15px;
  }
  .analytics-list-anker:hover{
    color: var(--whatsApp);
    text-decoration: underline;
  }
  .analytics-list-item{
    font-size: 12px;
    letter-spacing: 0.6px;
  }
  .analytics-list-click-count{
    background: var(--nav-bg);
    width: 30px;
    text-align: center;
    border-radius: 25px;
    color: var(--color);
    font-size: 12px;
    font-weight: 600;
  }

  /* --- Dasboard Newsfeed --- */
  .bio-tools-one{
    width: 100%;
    height: 200px;
    background: var(--whatsBBG);
    border: 1px solid var(--widgetBorder1);
    border-radius: 12px;
    padding: 10px 20px;
    position: relative;
  }
  .bio-tools-one span{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: #00000070;
    color: var(--color);
    border-radius: 50%;
    cursor: pointer;
    box-shadow: var(--shadow1);
    opacity: 0;
    visibility: hidden;
    z-index: 1;
  }
  .bio-tools-one:hover span{
    opacity: 1;
    visibility: visible;
  }
  .bio-tools-one:hover .title1, .bio-tools-one:hover .text{
    opacity: 0.2;
  }
  .bio-tools-one span i{
    position: relative;
    font-size: 3em;

  }

  .add-bio-buttons{

  }
  .add-bio-buttons p{
    font-size: 12px;
    color: var(--pText);
  }
  .add-bio-buttons span{
    background: var(--nav-bg);
    height: 30px;
    width: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
  }
  .add-bio-buttons span i{
    font-size: 2em;
    color: var(--color);
  }
  .bio-social-popup{
    transform: scale(0.5);
    opacity: 0;
    visibility: hidden;
  }
  .bio-social-links{
    position: absolute;
    top: -70px;
    left: 50%;
    transform: translateX(-50%);
    background: var(--default-bg);
    width: 50%;
    padding: 15px 20px;
    border-radius: 6px;
    box-shadow: var(--shadow1);
    z-index: 2;
  }

  .bio-social-links li{
    width: 100%;
    height: 40px;
    display: flex;
    gap: 5px;
    background: transparent;
    margin-bottom: 10px;
  }
  .bio-social-links li:last-child{
    margin-bottom: 0px;
  }
  .bio-social-links li label{
    width: 10%;
    line-height: 40px;
    text-align: center;
    background: var(--body);
    border: 1px solid var(--subscriptionBR);
    border-radius: 4px;
  }
  .bio-social-links li label i{
    font-size: 18px;
    color: var(--default2);
  }
  .bio-social-links li input{
    width: 85%;
    border: 1px solid var(--subscriptionBR);
    outline: none;
    background: transparent;
    border-radius: 4px;
    padding: 0px 10px;
    color: var(--color);
  }
  .social-save-leave-btn{
    background: var(--body);
    color: var(--default2);
    height: 30px;
    line-height: 10px;
  }
  .bio-social-links li input, .bio-social-links li label{
    height: 100%;
  }
  .bio-user-name input, .bio-user-bio textarea{
    background: transparent;
    border-style: none;
    width: 100%;
    outline: none;
    text-align: center;
    resize: none;
  }
  .bio-user-name input:focus, .bio-user-bio textarea:focus{
    color: var(--title1);
  }
  .bio-user-name label, .bio-user-bio label{
    color: var(--pText);
    word-break: keep-all;
    font-weight: 400;
    font-size: 14px;
  }
  .bio-user-name input{
    font-size: 16px;
    resize: none;
  }
  .Toastify__toast-theme--light {
    background: var(--default-bg) !important;
    color: var(--color) !important;
  }
  .Toastify__toast-theme--light svg{
    color: var(--color) !important;
  }
  .add-bio-buttons{
    position: relative;
  }
  .bio-button-submit{
    position: absolute;
    content: '';
    top: -50px;
    left: 50%;
    transform: translate(-50%);
    width: 40%;
    height: 190px;
    background: var(--default-bg);
    z-index: 2;
    border-radius: 4px;
    padding: 0px 10px;
    opacity: 0;
    visibility: hidden;
    box-shadow: var(--shadow1);
  }
  .bio-button-submit .label-up{
    height: 40%;
    width: 100%;
    background: #ffffff36;
    display: flex;
  }
  .bio-button-submit .label-up .up, .bio-button-submit label{
    font-size: 2em;
    line-height: 60px;
    text-align: center;
    width: 30%;
    height: 100%;
    background: #c490042f;
    position: relative;
    color: var(--color);
    border: 1px solid var(--pText);
  }

  .bio-button-submit .label-up .up input{
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
  }
  .bio-button-submit .label-up .label{
    width: 70%;
    height: 100%;
  }
  .bio-button-submit .label-up .label input{
    width: 100%;
    height: 100%;
    outline: none;
    border: 1px solid rgba(82, 40, 132, 0.57);
    padding: 0px 20px;
    background: #e4d6ff;
  }

  .bio-button-submit label{

  }
  .bio-button-submit input{
    
  }

  .bio-card-btn a{
    background: #421b7b0d;
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 12px;
    border: 1px solid var(--widgetBorder1) !important;
    color: var(--title2);
    width: 40%;
  }
  .bio-card-btn a i{
    font-size: 12px !important;
    margin-left: 2px;
  }
  .bio-grid-img {
    height: 250px;
    object-fit: cover;
    -webkit-filter: grayscale(100%) blur(1px);
  }
  .subscription:hover .bio-grid-img {
    -webkit-filter: grayscale(0%) blur(0px);
  }

  /* --- Update bio page ---  */
  .mamas-widget-area button{
    color: var(--title2) !important;
    font-size: 15px !important;
    font-weight: 600 !important;
  }
  .mamas-widget-area button.active{
    background: var(--spanBG) !important;
    color: var(--color) !important;
  }

  .bio-update-img-box{
    width: 100%;
    height: 300px;
    background: var(--widgetBackground);
    border: 1px dashed #52288491;
    border-radius: 6px;
    position: relative;
  }
  .bio-update-img-box .up-69{
    width: 50%;
    height: 60%;
  }

  .bio-update-img-box img, .bio-update-img-box .up-69  input{
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 60%;
    display: block;
  }

  .bio-update-img-box img{
    object-fit: cover;
  }

  .bio-update-img-box .up-69  input{
    opacity: 0;
    cursor: pointer;
  }
.set-bio-photo-btn{
  background: var(--body);
  border: 1px solid #52288491;
  padding: 5px 10px;
  box-shadow: var(--shadow3);
  border-radius: 4px;
}

/* --- color box ---- */

.color-box{
  background: var(--opacityBG);
  border: 1px solid var(--subscriptionBR);
  padding: 5px 10px;
  gap: 5px;
  width: 45%;
}
.color-box input {
  width: 30px;
  height: 30px;
  border: 1px solid var(--subscriptionBR);
  cursor: pointer;
  border-radius: 50%;
}
.color-picker-label {
  margin-bottom: 5px !important;
}
.color-box .recust{
  width: 100%;
  border-radius: 25px;
  padding: 0px 10px;
}

.mamas-bio-details input, .mamas-bio-details textarea{
  border-style: none;
  outline: none;
  text-align: center;
  background: transparent;
  resize: none;
}

.parents-of-social-buttons button{
  font-size: 13px !important;
  color: var(--default2) !important;
  letter-spacing: 0.5px;
}
.buttons-update, .buttons-update .btn-item{
  width: 100%;
}
.buttons-update .btn-item{
  background: var(--widgetBackground);
  border: 1px solid var(--widgetBorder1);
  padding: 4px 6px;
  border-radius: 4px;
}
.buttons-update .btn-item label{
  width: 20%;
  color: var(--pText);
}
.buttons-update .btn-item input{
  width: 80%;
  outline: none;
  border: 1px solid var(--color2);
  padding: 2px 5px;
  border-radius: 4px;
  background: transparent;
}
.buttons-update .social-link label{
  width: 10%;
}
.buttons-update .social-link input{
  width: 90%;
}
.btn-item i{
  font-size: 20px;
}
.forM-btn button{
  position: absolute;
  content: '';
  top: 20px;
  right: 30px;
  box-shadow: var(--shadow1);
  background: transparent;
  border-color: #421b7b21;
}
.forM-btn button:hover{
  transform: scale(1.1);
}