@media screen and (max-width: 768px){

    .mamas-mob-vh{
        height: 100vh !important;
    }
    .mamas-mob-vh .xs-vh{
        height: 90vh !important;
        margin:30px auto !important;
    }
    .bio-button-submit {
        width: 85%;
    }
    .bio-social-links {
        width: 85%;
    }
    .btn-1-0-0{
        width: 80% !important;
    }

}
@media screen and (max-width: 968px){

    .mamas-bio-button {
        height: 50px;
        margin-bottom: 5px;
    }
    .mamas-bio-button img {
        width: 15%;
    }
    .bio-button-submit {
        width: 65%;
    }
    .bio-social-links {
        width: 75%;
    }
    .btn-1-0-0{
        width: 75% !important;
    }
    

}
